import * as React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

type userPage = any;

const UserPage = ({ pageContext }: userPage) => {
  // console.log(pageContext.data);
  return (
    <div>
      {/* <Helmet>
        <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
      </Helmet> */}
      <Layout page={pageContext.data} />
    </div>
  );
};

export default UserPage;
